import { Button, ButtonProps } from "@fonoa/ui-components/button";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  IconProps,
  TriangleWarningIcon,
} from "@fonoa/ui-components/icons";
import { Tag, TagProps } from "@fonoa/ui-components/tag";
import { Position, Tooltip } from "@fonoa/ui-components/tooltip";
import { DataAttributes, SpacingProps, ValidTailwindSizes } from "@fonoa/ui-components/types";
import { Typography } from "@fonoa/ui-components/typography";
import { getSpacingClass, KEYS, noop, useOnKeyPress } from "@fonoa/ui-components/utils";
import classNames from "classnames";
import { ComponentType, ReactNode, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import CheckboxList from "@/components/Form/CheckboxList";

type FilterColor = "defaultColor" | "dark50";
type FilterState = "active" | "showPanel" | "inactive";

const tagColorsByState: { [key in FilterColor]: { [key in FilterState]: TagProps["color"] } } = {
  defaultColor: {
    inactive: "blueGray",
    showPanel: "blueGray200",
    active: "blue",
  },
  dark50: {
    inactive: "blueGray50",
    showPanel: "blueGray200",
    active: "blue",
  },
};

function getTagColor(
  showPanel: boolean,
  active: boolean,
  filterColor: FilterColor
): TagProps["color"] {
  if (active) return tagColorsByState[filterColor].active;
  if (showPanel) return tagColorsByState[filterColor].showPanel;

  return tagColorsByState[filterColor].inactive;
}
interface FilterItemProps<Value> extends SpacingProps {
  onApply: (value: Value | undefined) => void;
  component: (
    value: Value | undefined,
    onChange: (value: Value | undefined) => void
  ) => JSX.Element;
  withApplyButton?: boolean;
  withClearButton?: boolean;
  withArrow?: boolean;
  panelPosition?: "left" | "center" | "right";
  panelWidth?: `w-${ValidTailwindSizes | "auto"}`;
  filterColor?: FilterColor;
  selectedItemMaxWidth?: string;
  selectedItemTooltioMaxWidth?: string;
  footerStyle?: "default" | "clean";
  active?: boolean;
  value: Value;
  tooltipPosition?: Position;
  dataAttributes?: DataAttributes;
  initialShowPanel?: boolean;
  buttonProps?: ButtonProps;
  title: ReactNode | ((params: { isExpanded: boolean }) => ReactNode);
  onClick?: (v: { open: boolean }) => void;
  renderCurrentValue?: (value: NonNullable<Value>) => ReactNode;
  renderTooltipValue?: (value: NonNullable<Value>) => ReactNode;
  leftIcon?: ComponentType<IconProps>;
  iconProps?: Omit<IconProps, "name">;
  excludeOnRemoveClick?: boolean;
  withFilterTitleOnSelected?: boolean;
}

export default function FilterItem<Value>({
  onApply = noop,
  title,
  component,
  withApplyButton = true,
  withClearButton = true,
  withArrow = false,
  panelPosition = "center",
  panelWidth = "w-80",
  filterColor = "defaultColor",
  selectedItemMaxWidth = "max-w-30",
  selectedItemTooltioMaxWidth = "w-auto",
  footerStyle = "default",
  tooltipPosition = "top",
  initialShowPanel,
  value,
  active = false,
  dataAttributes = {},
  spacing,
  onClick = noop,
  renderCurrentValue,
  renderTooltipValue,
  withFilterTitleOnSelected,
  leftIcon,
  iconProps = {},
  excludeOnRemoveClick,
}: FilterItemProps<Value>) {
  const [showPanel, setShowPanel] = useState(initialShowPanel || false);
  const [localValue, setLocalValue] = useState<Value | undefined>(value);

  useEffect(() => setLocalValue(value), [value, setLocalValue]);
  useOnKeyPress(KEYS.ESCAPE, () => setShowPanel(false), showPanel);

  const spacingClass = getSpacingClass(spacing);

  const hasValueApplied = Boolean(Array.isArray(value) ? value?.length : value);

  return (
    <div className={classNames("relative inline-block text-left", spacingClass)}>
      <button
        onClick={() => {
          onClick({ open: showPanel || false });
          setShowPanel(!showPanel);
        }}
      >
        <Tag
          dataAttributes={dataAttributes}
          color={getTagColor(showPanel, active, filterColor)}
          rounded={false}
          state={active ? "selected" : "default"}
          icon={leftIcon}
          iconProps={iconProps}
          onClose={
            withClearButton && hasValueApplied && !excludeOnRemoveClick
              ? (event) => {
                  event.stopPropagation();
                  onApply(undefined);
                }
              : undefined
          }
          UNSAFE_className="!py-0.75"
        >
          <div className="flex items-center justify-between">
            {renderCurrentValue && hasValueApplied && value ? (
              <Tooltip
                panelPadding="1"
                body={renderTooltipValue ? renderTooltipValue(value) : renderCurrentValue(value)}
                offset={[0, 12]}
                widthClassName={selectedItemTooltioMaxWidth}
                textAlign="center"
                position={tooltipPosition}
              >
                <div className="flex items-center">
                  <p className={classNames("truncate", selectedItemMaxWidth)}>
                    {withFilterTitleOnSelected ? (
                      <>
                        <>
                          {typeof title === "function" ? title({ isExpanded: showPanel }) : title}
                          {": "}
                        </>
                        <span className="font-medium">{renderCurrentValue(value)}</span>
                      </>
                    ) : (
                      renderCurrentValue(value)
                    )}
                  </p>
                </div>
              </Tooltip>
            ) : (
              <>{typeof title === "function" ? title({ isExpanded: showPanel }) : title}</>
            )}

            {withArrow && (!hasValueApplied || !withClearButton) && (
              <div className="ml-3">
                {showPanel
                  ? ArrowUpIcon && <ArrowUpIcon size={12} />
                  : ArrowDownIcon && <ArrowDownIcon size={12} />}
              </div>
            )}
          </div>
        </Tag>
      </button>
      {showPanel && (
        <div className="relative z-20" data-testid="filter-item-panel">
          <div
            // eslint-disable-next-line tailwindcss/classnames-order
            className={classNames(
              `absolute mt-2 z-10 overflow-hidden rounded border border-blueGray200 shadow-lg focus:outline-none`,
              panelWidth,
              {
                "left-1/2 -translate-x-1/2": panelPosition === "center",
                "left-0": panelPosition === "left",
                "right-0": panelPosition === "right",
              }
            )}
          >
            <div>
              {component(localValue, (val) => {
                if (withApplyButton) {
                  setLocalValue(val);
                } else {
                  onApply(val);
                  setShowPanel(false);
                }
              })}
            </div>
            {withApplyButton || withClearButton ? (
              <div
                className={classNames("flex flex-row px-4", {
                  "border-t border-blueGray200 bg-blueGray10 py-3": footerStyle === "default",
                  "bg-white pb-2": footerStyle === "clean",
                  // when there is only Clear button - it should be aligned to the left, otherwise to the right
                  "justify-start": withClearButton && !withApplyButton,
                  "justify-end": (withClearButton && withApplyButton) || withApplyButton,
                })}
              >
                {withClearButton ? (
                  <span
                    className={classNames("cursor-pointer py-2 text-sm text-blueGray600", {
                      "mr-4": withApplyButton,
                    })}
                    onClick={() => {
                      onApply(undefined);
                      setLocalValue(undefined);
                      setShowPanel(false);
                    }}
                  >
                    <FormattedMessage defaultMessage="Clear" id="/GCoTA" />
                  </span>
                ) : null}
                {withApplyButton ? (
                  <Button
                    dataAttributes={{ cy: "apply-filter" }}
                    size="SMALL"
                    onClick={() => {
                      onApply(localValue);
                      setShowPanel(false);
                    }}
                  >
                    <FormattedMessage defaultMessage="Apply" id="EWw/tK" />
                  </Button>
                ) : null}
              </div>
            ) : null}
          </div>

          <div
            className="fixed top-0 left-0 z-0 h-screen w-screen"
            onClick={() => setShowPanel(false)}
          />
        </div>
      )}
    </div>
  );
}

export interface FilterOptions<T> {
  options?: T[];
  isLoading?: boolean;
  isError?: boolean;
}
export interface FilterOption {
  value: string;
  label: string;
}

export interface MultipleOptionFilterItem {
  name: string;
  allItemsLabel: string;
  enableSearch?: boolean;
  selectedItems?: string[];
  filterOptions: FilterOptions<FilterOption>;
  updateFilters: (val?: string[]) => void;
  panelPosition?: "center" | "right" | "left";
  sortOptions?: boolean;
  showFirstNameOnMultiselect?: boolean;
}

export const fromArray = (array?: string[]) => array?.join(",");
export const toArray = (value?: string) => value?.split(",");

const getSelectedLabel = (
  filterName: string,
  options: FilterOption[],
  selectedItems: string[],
  showFirstNameOnMultiselect: boolean
) => {
  if (selectedItems.length == 0) {
    return filterName;
  }

  if (selectedItems.length === 1) {
    return options.find((co) => co.value === selectedItems[0])?.label ?? "";
  }

  if (!showFirstNameOnMultiselect) {
    return `${filterName} (${selectedItems?.length})`;
  }

  const firstOptionName = options.find((co) => co.value === selectedItems[0])?.label ?? "";

  return `${firstOptionName} (+${selectedItems.length - 1})`;
};

export const MultipleOptionFilterItem = ({
  name,
  updateFilters,
  selectedItems,
  allItemsLabel,
  filterOptions,
  enableSearch = false,
  panelPosition = "center",
  sortOptions = true,
  showFirstNameOnMultiselect = false,
}: MultipleOptionFilterItem) => {
  const { options = [], isLoading, isError } = filterOptions || {};

  return (
    <FilterItem
      title={name}
      onApply={updateFilters}
      value={selectedItems || []}
      withApplyButton={!isError && !isLoading}
      withClearButton={!isError && !isLoading}
      selectedItemMaxWidth="w-max-auto"
      selectedItemTooltioMaxWidth="max-w-40"
      tooltipPosition="bottom"
      panelPosition={panelPosition}
      renderTooltipValue={() => (
        <span>
          {options
            .filter((co) => selectedItems && selectedItems.some((si) => si === co.value))
            ?.map((co) => co.label)
            .join(", ")}
        </span>
      )}
      renderCurrentValue={() => (
        <div className="flex items-center whitespace-nowrap">
          {getSelectedLabel(name, options, selectedItems || [], showFirstNameOnMultiselect)}
        </div>
      )}
      active={Boolean(selectedItems?.length)}
      component={(itemValue, onChange) => (
        <>
          {isLoading && (
            <Typography color="text-blueGray500" spacing={{ py: 5 }} align="text-center">
              <FormattedMessage defaultMessage="Loading options..." id="TDUDK6" />
            </Typography>
          )}
          {isError && (
            <div className="flex items-center justify-center gap-1 py-5">
              <TriangleWarningIcon size={14} className="fill-current text-red600" />
              <Typography color="text-blueGray500">
                <FormattedMessage defaultMessage="Couldn't load filter options" id="zIwGxw" />
              </Typography>
            </div>
          )}
          {!isLoading && !isError && (
            <CheckboxList
              allItemsLabel={allItemsLabel}
              items={sortOptions ? options.sort((a, b) => a.label.localeCompare(b.label)) : options}
              alwaysShowAllItemsLabel
              showSelectedItems={false}
              enableSearch={enableSearch}
              onChange={onChange}
              value={itemValue}
            />
          )}
        </>
      )}
      panelWidth="w-80"
    />
  );
};
