import classNames from "classnames";
import { ComponentType, DetailedHTMLProps, HTMLAttributes, MouseEvent, ReactNode } from "react";

import { CloseButton } from "../button";
import { IconProps } from "../icons";
import { DataAttributes, SpacingProps, TextTransform } from "../types";
import { convertDataAttributes, getSpacingClass } from "../utils";

type Size = "xs" | "sm" | "md";
type State = "default" | "selected";
type Color =
  | "blueGray"
  | "blue"
  | "green"
  | "green300"
  | "yellow"
  | "yellow400"
  | "tangerine"
  | "tangerine400"
  | "red"
  | "red300"
  | "teal"
  | "purple"
  | "blueGray50"
  | "blueGray200"
  | "blueGray300"
  | "blueGray700"
  | "blueGray900"
  | "default";

const COLORS: Record<Color, string> = {
  blueGray: "bg-blueGray25 focus:bg-blueGray100 text-blueGray600",
  blueGray50: "bg-blueGray50 focus:bg-blueGray100 text-blueGray600",
  blueGray200: "bg-blueGray200 text-blueGray700",
  blueGray300: "bg-blueGray300 text-blueGray700",
  blueGray700: "bg-blueGray700 text-white",
  blueGray900: "bg-white bg-opacity-70 text-blueGray900",
  blue: "bg-primaryBlue25 focus:bg-primaryBlue100 text-primaryBlue700",
  green: "bg-green10 focus:bg-green100 text-green700",
  green300: "bg-green10 focus:bg-green300 text-green700",
  yellow: "bg-yellow25 focus:bg-yellow100 text-yellow800",
  yellow400: "bg-yellow25 focus:bg-yellow400 text-yellow800",
  tangerine: "bg-tangerine25 focus:bg-tangerine100 text-tangerine700",
  tangerine400: "bg-tangerine25 focus:bg-tangerine400 text-tangerine700",
  red: "bg-red25 focus:bg-red100 text-red700",
  red300: "bg-red25 focus:bg-red300 text-red700",
  teal: "bg-teal25 focus:bg-teal100 text-teal700",
  purple: "bg-purple25 focus:bg-purple100 text-purple700",
  default: "bg-blueGray300 text-blueGray700",
};

const COLOR_MODS: Record<Color, Record<State, string>> = {
  blueGray: {
    default: "border border-transparent",
    selected: "border border-blueGray500",
  },
  blueGray50: {
    default: "border border-transparent",
    selected: "border border-blueGray500",
  },
  blueGray200: {
    default: "border border-transparent",
    selected: "border border-blueGray200",
  },
  blueGray300: {
    default: "border border-transparent",
    selected: "border border-blueGray300",
  },
  blueGray700: {
    default: "",
    selected: "",
  },
  blueGray900: {
    default: "",
    selected: "",
  },
  blue: {
    default: "border border-transparent",
    selected: "border border-primaryBlue500",
  },
  green: {
    default: "border border-transparent",
    selected: "border border-green500",
  },
  green300: {
    default: "border border-transparent",
    selected: "border border-green500",
  },
  yellow: {
    default: "border border-transparent",
    selected: "border border-yellow500",
  },
  yellow400: {
    default: "border border-transparent",
    selected: "border border-yellow700",
  },
  tangerine: {
    default: "border border-transparent",
    selected: "border border-tangerine700",
  },
  tangerine400: {
    default: "border border-transparent",
    selected: "border border-tangerine700",
  },
  red: {
    default: "border border-transparent",
    selected: "border border-red500",
  },
  red300: {
    default: "border border-transparent",
    selected: "border border-red500",
  },
  teal: {
    default: "border border-transparent",
    selected: "border border-teal500",
  },
  purple: {
    default: "border border-transparent",
    selected: "border border-purple500",
  },
  default: {
    default: "border border-transparent",
    selected: "border border-blueGray300",
  },
};

const SHAPES: Record<"rounded" | "square" | "squareSm", string> = {
  rounded: "rounded-full",
  square: "rounded",
  squareSm: "rounded-sm",
};

const SIZES: Record<Size, string> = {
  xs: "px-1.5 py-0 text-xs leading-4",
  sm: "px-1.5 py-0.5 text-xs leading-4",
  md: "px-2 py-1.5 text-sm leading-6",
};

const CLOSE_SIZES: Record<Size, number> = {
  xs: 10,
  sm: 12,
  md: 16,
};

const CLOSE_SIZE_CLASSES: Record<Size, string> = {
  xs: "ml-2",
  sm: "ml-2",
  md: "ml-2.5",
};

const LABEL_CLASSES: Record<Size, string> = {
  xs: "ml-1",
  sm: "ml-1.5",
  md: "ml-2",
};

const CLOSE_COLORS: Record<Color, string> = {
  blueGray: "text-blueGray600",
  blueGray50: "text-blueGray600",
  blueGray200: "text-blueGray600",
  blueGray300: "text-blueGray600",
  blueGray700: "text-blueGray700",
  blueGray900: "text-blueGray900",
  blue: "text-primaryBlue700",
  green: "text-green700",
  green300: "text-green700",
  yellow: "text-yellow800",
  yellow400: "text-yellow800",
  tangerine: "text-tangerine700",
  tangerine400: "text-tangerine700",
  red: "text-red700",
  red300: "text-red700",
  teal: "text-teal700",
  purple: "text-purple700",
  default: "text-blueGray600",
};

interface SharedProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    SpacingProps {
  labelClassName?: string;
  size?: Size;
  state?: State;
  color?: Color;
  rounded?: boolean;
  children?: ReactNode;
  onClose?: (event: MouseEvent<HTMLDivElement>) => void;
  tabIndex?: number;
  dataAttributes?: DataAttributes;
  transform?: TextTransform;
  UNSAFE_className?: string;
}

type ConditionalIconProps =
  | { icon?: ComponentType<IconProps>; iconProps?: IconProps }
  | { icon?: undefined; iconProps?: never };

export type TagProps = SharedProps & ConditionalIconProps;

export function Tag({
  children,
  labelClassName,
  size = "md",
  state = "default",
  icon: Icon,
  iconProps,
  rounded = true,
  color = "blueGray",
  onClose,
  tabIndex = 0,
  spacing,
  dataAttributes,
  UNSAFE_className,
  transform,
  ...props
}: TagProps) {
  return (
    <div className="flex" {...convertDataAttributes(dataAttributes)}>
      <div
        tabIndex={tabIndex}
        className={classNames(
          "flex items-center",
          COLORS[color],
          COLOR_MODS[color][state],
          SHAPES[rounded ? "rounded" : size !== "xs" ? "square" : "squareSm"],
          SIZES[size],
          getSpacingClass(spacing),
          transform,
          UNSAFE_className
        )}
        {...props}
      >
        {Icon && <Icon {...iconProps} />}
        <span
          className={classNames("whitespace-nowrap", labelClassName, {
            [LABEL_CLASSES[size]]: Boolean(Icon),
          })}
        >
          {children}
        </span>

        {onClose && (
          <CloseButton
            onClose={onClose}
            variant={rounded ? "filled" : "ghost"}
            spacing={CLOSE_SIZE_CLASSES[size] === "sm" ? { ml: 2 } : { ml: 2.5 }}
            iconClassName={CLOSE_COLORS[color]}
            size={CLOSE_SIZES[size]}
            dataAttributes={{ testid: "tag-close-icon" }}
          />
        )}
      </div>
    </div>
  );
}
