import { Checkbox } from "@fonoa/ui-components/checkbox";
import { Input } from "@fonoa/ui-components/input";
import classNames from "classnames";
import Image from "next/image";
import { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Pill, { SizeVariant } from "@/components/Labels/Pill";
import getCountryFlagPath from "@/lib/countryFlagsPath";

interface Item {
  value: string;
  label: string;
}

type ItemArrayOrMap = Item[] | { [groupName: string]: Item[] };

interface CheckboxListProps {
  items: ItemArrayOrMap;
  value?: string[];
  onChange?: (selected: string[]) => void;
  placeholder?: string;
  selectedItemsLabel?: string;
  allItemsLabel?: string;
  alwaysShowAllItemsLabel?: boolean;
  showSelectedCountryFlags?: boolean;
  showSelectedItems?: boolean;
  enableSearch?: boolean;
  width?: `w-${string}`;
}

const CheckboxRow = ({
  label,
  index,
  checked,
  onChange,
}: {
  label: string;
  index: number;
  checked: boolean;
  onChange: () => void;
}) => (
  <Checkbox
    checked={checked}
    onChange={onChange}
    label={label}
    spacing={{ py: 3.5, pl: 5 }}
    bgColor={index % 2 ? "bg-blueGray10" : "bg-white"}
    inputClassName="rounded-sm border-blueGray200"
  />
);

const DisplayItems = ({
  items,
  selected,
  handleItemChange,
}: {
  items: ItemArrayOrMap;
  selected: string[];
  handleItemChange: (item: Item) => void;
}) => {
  if (Array.isArray(items)) {
    return (
      <>
        {items.map((item, index) => (
          <CheckboxRow
            key={`item-${item?.value}`}
            index={index}
            label={item.label}
            checked={selected.includes(item.value)}
            onChange={() => handleItemChange(item)}
          />
        ))}
      </>
    );
  }

  return (
    <>
      {Object.entries(items).map(([groupName, items]) => {
        return (
          <>
            <span className="flex h-12 items-center border-b border-b-blueGray100 bg-white pl-5 text-blueGray600">
              {groupName}
            </span>
            {items.map((item, index) => (
              <CheckboxRow
                key={`item-${item?.value}`}
                index={index}
                label={item.label}
                checked={selected.includes(item.value)}
                onChange={() => handleItemChange(item)}
              />
            ))}
          </>
        );
      })}
    </>
  );
};

const SelectedItems = ({
  items,
  selectedItemsLabel,
  onClick,
  showSelectedCountryFlags,
}: {
  items: Item[];
  selectedItemsLabel: string | undefined;
  onClick: (item: Item) => void;
  showSelectedCountryFlags: boolean;
}) => {
  if (!items.length) {
    return null;
  }

  return (
    <>
      <div className="border-b border-blueGray200 py-2 px-5 text-sm text-blueGray600">
        {selectedItemsLabel || <FormattedMessage defaultMessage="Selected options" id="EtJkCo" />}
      </div>
      <div className="flex flex-wrap bg-white pt-3 pr-4 pb-2 pl-5">
        {items.map((item, index) => (
          <Pill
            key={item?.value || index}
            spacing={{ mr: 1, mb: 1.5 }}
            type={SizeVariant.SMALL}
            onClick={() => onClick(item)}
            onRemoveClick={() => onClick(item)}
          >
            {showSelectedCountryFlags && (
              <Image
                alt={item?.label}
                className="h-auto max-w-full rounded-full"
                src={getCountryFlagPath(item.value, "1x1")}
                height={14}
                width={14}
              />
            )}
            <div className={classNames({ "ml-1": showSelectedCountryFlags })}>{item.label}</div>
          </Pill>
        ))}
      </div>
    </>
  );
};

const lowerCasePredicate = (searchString: string) => (val: Item) =>
  val.label.toLowerCase().includes(searchString.toLowerCase());
export default function CheckboxList({
  value,
  placeholder,
  onChange,
  selectedItemsLabel,
  allItemsLabel,
  items,
  showSelectedCountryFlags,
  showSelectedItems = true,
  enableSearch = true,
  alwaysShowAllItemsLabel = false,
  width = undefined,
}: CheckboxListProps) {
  const [selected, setSelected] = useState<string[]>(value || []);
  const [search, setSearch] = useState<string>("");
  const intl = useIntl();

  useEffect(() => {
    setSelected(value || []);
  }, [value]);

  const filteredItems = useMemo(() => {
    if (!search) return items;

    if (Array.isArray(items)) {
      return items.filter(lowerCasePredicate(search));
    }

    return Object.fromEntries(
      Object.entries(items)
        .map(([key, items]) => [key, items.filter(lowerCasePredicate(search))])
        .filter(([, items]) => items.length > 0)
    ) as { [groupName: string]: Item[] };
  }, [search, items]);

  const selectedItems = useMemo(() => {
    if (search) {
      return [];
    }

    if (Array.isArray(filteredItems)) {
      return filteredItems?.filter((val) => selected?.includes(val?.value));
    }

    return Object.values(filteredItems)
      .flat()
      .filter((val) => selected?.includes(val?.value));
  }, [filteredItems, search, selected]);

  const handleItemChange = (item: Item) => {
    const newSelected = [...selected];
    const foundIndex = newSelected.indexOf(item.value);

    if (foundIndex !== -1) {
      newSelected.splice(foundIndex, 1);
    } else {
      newSelected.push(item.value);
    }

    setSelected(newSelected);
    onChange?.(newSelected);
  };

  const hasVisibleItems = Array.isArray(filteredItems)
    ? Boolean(filteredItems.length)
    : Object.entries(filteredItems).some(([, items]) => items.length > 0);

  return (
    <div className={classNames("flex flex-col", width)}>
      {enableSearch && (
        <Input
          dataAttributes={{ cy: "search-checkbox-input" }}
          placeholder={
            placeholder || intl.formatMessage({ defaultMessage: "Search Here", id: "/U51/5" })
          }
          value={search}
          color="PRIMARY_BLUE_900"
          onChange={(event) => setSearch(event.target.value)}
          // Enforces overwrite in DepreciatedInput
          // eslint-disable-next-line tailwindcss/enforces-shorthand
          className="h-12 border-transparent pr-5 pl-5 focus:border-transparent focus:ring-transparent"
          fluid
          autoFocus
          rounded={false}
        />
      )}
      <div
        className={classNames("max-h-80 flex-1 overflow-y-auto  border-blueGray200 bg-blueGray10", {
          "border-t": enableSearch,
        })}
      >
        {hasVisibleItems ? (
          <>
            {showSelectedItems ? (
              <SelectedItems
                items={selectedItems}
                selectedItemsLabel={selectedItemsLabel}
                onClick={handleItemChange}
                showSelectedCountryFlags={Boolean(showSelectedCountryFlags)}
              />
            ) : null}
            {((showSelectedItems && selectedItems.length > 0) || alwaysShowAllItemsLabel) && (
              <div
                className={classNames(
                  "border-b border-blueGray200 py-2 px-5 text-sm text-blueGray600",
                  { "border-t": showSelectedItems && selectedItems.length > 0 }
                )}
              >
                {allItemsLabel || <FormattedMessage defaultMessage="All options" id="O2xUzv" />}
              </div>
            )}
            <DisplayItems
              items={filteredItems}
              selected={selected}
              handleItemChange={handleItemChange}
            />
          </>
        ) : (
          <div className="py-3 pl-5 text-blueGray600">
            <FormattedMessage defaultMessage="No results" id="jHJmjf" />
          </div>
        )}
      </div>
    </div>
  );
}
